.offers {
  padding-top: 70px;
  padding-bottom: 70px;
  text-align: center;
  color: white;
}

.offers > h3 {
  color: #e3d323;
  text-transform: uppercase;
}

.offers > p {
  color: white;
  text-transform: uppercase;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 45px;
}

.offers .container .box > img {
  width: 100%;
}

.offers .container .box {
  position: relative;
  overflow: hidden;
}
/* .offers .container .box .black__bg {
  background-color: black;
  position: absolute;
  width: 100%;
  height: 40px;
  bottom: 53px;
  -webkit-transform: rotate(-5deg);
  -ms-transform: rotate(-5deg);
  transform: rotate(-5deg);
} */

.offers .container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

  grid-column-gap: 40px;
  grid-row-gap: 60px;
}

.offers .box .holder {
  background-color: black;
  height: 60px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: self-start;
  padding-left: 26px;
  gap: 6px;
}
.offers .box .black__bg {
  position: absolute;
  background: #e3d323;
  width: 110%;
  height: 11px;
  bottom: 86px;
  left: -2px;
  transform: rotate(-5deg);
}

.offers .box .holder span:first-of-type {
  color: #e3d323;
  font-weight: bold;
}

.offers .box .holder span:last-of-type {
  color: white;
  font-weight: bold;
}
.offers .box .holder::before {
  content: "";
  position: absolute;
  width: 109%;
  height: 56px;
  background-color: black;
  bottom: 35px;
  left: -12px;
  transform: rotate(-5deg);
  z-index: -1;
}
