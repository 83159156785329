.nav {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 65px;
  transition-timing-function: ease-in;
  transition: all 0.5s;
  width: 100%;
  z-index: 99;
  text-transform: uppercase;
}

.menu__icon {
  display: none;
}

header > .image > img {
  height: 50px;
}

.nav__togled {
  height: 345px;
}

.video__togled {
  filter: blur(7px);
}

.balck_bg_toggle {
  background-color: black;
  border-bottom: 1px solid white;
}

@media (max-width: 660px) {
  header > nav > ul {
    flex-direction: column;
    width: 100%;
    position: absolute;
    left: 0;
    top: 60px;
    text-align: center;
    transition: 0.5s;
    height: 0;
    overflow: hidden;
  }
  .menu__icon {
    display: block;
    color: white;
  }
  .nav {
    padding: 35px 30px;
    justify-content: space-between;
  }
  .info > .one {
    font-size: 17px !important;
  }
  .info > .two {
    font-size: 35px !important;
  }
  .info > .three {
    font-size: 40px !important;
  }
  .info > button {
    font-size: 11px !important;
  }
}

header > nav > ul {
  display: flex;
  gap: 20px;
}

header > nav > ul > li {
  margin-right: 10px;
  padding: 15px 0;
}

header > nav > ul > li a {
  color: white;
  text-decoration: none;
}
header > nav > ul > li a:hover {
  color: #e3d323;
  transition: 0.5s;
}

header > nav > ul > li .active {
  color: #e3d323;
}
.nav__black {
  background-color: black;
}

.video__landing {
  position: relative;
  transition: 0.5s;
}
.video__landing::before {
  position: absolute;
  content: "";
  width: 100%;
  z-index: 1;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.8) 0,
    rgba(0, 0, 0, 0) 60%,
    rgba(0, 0, 0, 0.8) 100%
  );
}
.video__landing > video {
  width: 100%;
  object-fit: cover;
  height: 100vh;
  background-position: center;
}

.info {
  position: absolute;
  color: white;
  top: 50%;
  transform: translate(50%, -50%);
  right: 50%;
  z-index: 4;
  text-transform: uppercase;
}

.info > .one {
  font-size: 25px;
  font-weight: 500;
}
.info > .three {
  font-size: 70px;
  font-weight: bold;
}
.info > .two {
  font-size: 70px;
  font-weight: bold;
}
.info > .two > span {
  color: #e3d323;
}
.info > button {
  background-color: #e3d323;
  color: black;
  border: none;
  padding: 15px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
  transition: 0.5s;
}

.info > button:hover {
  box-shadow: 0 0 10px #e3d323;
  color: white;
}

.black-bb {
  background-color: black;
}
