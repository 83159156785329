.footer {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: black;
  color: white;
}

.footer .container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

  grid-column-gap: 40px;
  grid-row-gap: 60px;
}

.footer .container .box img {
  width: 60px;
  margin-bottom: 10px;
}

.footer .container .box {
  display: flex;
  flex-direction: column;
}

.footer .container .box p {
  line-height: 1.4;
  color: #a9a9a9;
  max-width: 270px;
  margin-bottom: 16px;
}

.footer .container .box h1 {
  margin-bottom: 30px;
  color: white;
  text-transform: uppercase;
  font-size: 22px;
}

.footer .container .box ul {
}

.footer .container .box li a {
  text-decoration: none;
  color: #a9a9a9;
  text-transform: uppercase;
  transition: 0.5s;
}

.footer .container .box li a:hover {
  color: #e3d323;
}

.footer .container .box li {
  margin-bottom: 10px;
}

.footer .container .box .hold {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}

.footer .container .box .icons .icon {
  margin-right: 10px;
}

.footer .container .box .icons .icon {
  transition: 0.5s;
  cursor: pointer;
}

.footer .container .box .icons .icon:hover {
  color: #e3d323;
}

.footer .container .box .hold a {
  text-transform: uppercase;
  text-decoration: none;
  color: #a9a9a9;
  transition: 0.5s;
}

.footer .container .box .hold a:hover {
  color: #e3d323;
}

.footer .container .box .hold .icon {
}
.footer .container .box .hold span {
  background-color: #e3d323;
  padding: 10px 13px;
  border-radius: 50%;
  color: black;
}

@media (max-width: 670px) {
  .footer {
    text-align: center;
  }

  .footer .container .box p {
    margin: 0 auto 16px;
  }
  .footer .container .box img {
    margin: 0 auto 10px;
  }
  .footer .container .box .hold {
    margin: 0 auto 20px;
  }

  .footer .container .box h1 {
    font-size: 17px;
  }
  .footer .container .box li a {
    font-size: 12px !important;
  }

  .footer .container .box .hold a {
    font-size: 14px;
  }

  .footer .container .box .hold span {
    padding: 7px 9px !important;
  }
  .footer .container .box .hold .icon {
    font-size: 20px !important;
  }
}
